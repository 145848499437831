import React from 'react'

export const Disclaimer = () => {
  return (
    <div className='bg--blue x color--white'>
      <div className='container--1000 mxa p2 tc outer'>
        <div>
          <p>Pansy...</p>
        </div>
      </div>
    </div>
  )
}
